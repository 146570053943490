.app {
  height: 100vh;
  display: flex;
}

.app .content {
  margin: 5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.app .navigation {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid #eee;
  font-size: 2rem;
  font-weight: 300;
  color: #666;
}

.app .navigation .prime {
  width: 66%;
  line-height: 1.35;
}

.app a {
  color: #000;
}

.app .logo img {
  height: 1.5rem;
}

.app .mail {
  font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .app .content {
    margin: 2rem;
  }
  .app .navigation .prime {
    width: 100%;
  }
  .app .logo img {
    height: 1rem;
  }

  .app .navigation {
    margin-top: 1rem;
    padding-top: 1rem;
    font-size: 1.5rem;
  }

  .app .mail {
    font-size: 1rem;
  }
}



.sm-link{
  --uismLinkDisplay: var(--smLinkDisplay, inline-flex);
  --uismLinkTextColor: var(--smLinkTextColor);
  --uismLinkTextColorHover: var(--smLinkTextColorHover);

  display: var(--uismLinkDisplay);
  color: var(--uismLinkTextColor);
  position: relative;
  overflow: hidden;
}

a.sm-link{
  text-decoration: none;
}

.sm-link__label{
  display: block;
}

/* sm-link_padding-all */

.sm-link_padding-all{
  --uismLinkLineWeight: var(--smLinkLineWeight, 2px);
  --uismLinkLineColor: var(--smLinkLineColor, #000);
  --uismLinkPadding: var(--smLinkPadding, 5px);

  padding: var(--uismLinkPadding);
}

.sm-link_padding-all::before,
.sm-link_padding-all::after{
  width: 100%;
  height: var(--uismLinkLineWeight);
  left: 0;
}

.sm-link_padding-all::before{
  top: 0;
}

.sm-link_padding-all::after{
  bottom: 0;
}

.sm-link_padding-all .sm-link__label::before,
.sm-link_padding-all .sm-link__label::after{
  width: var(--uismLinkLineWeight);
  height: 100%;
  top: 0;
}

.sm-link_padding-all .sm-link__label::before{
  left: 0;
}

.sm-link_padding-all .sm-link__label::after{
  right: 0;
}

.sm-link_padding-all::before,
.sm-link_padding-all::after,
.sm-link_padding-all .sm-link__label::before,
.sm-link_padding-all .sm-link__label::after{
  content: "";
  background-color: var(--uismLinkLineColor);
  position: absolute;
  opacity: 0;

  will-change: transform, opacity;
  transition-property: transform, opacity;
}

.sm-link_padding-all:hover::before,
.sm-link_padding-all:hover::after,
.sm-link_padding-all:hover .sm-link__label::before,
.sm-link_padding-all:hover .sm-link__label::after{
  opacity: 1;
}

/* sm-link_padding-bottom */

.sm-link_padding-bottom{
  --uismLinkLineWeight: var(--smLinkLineWeight, 2px);
  --uismLinkLineColor: var(--smLinkLineColor, #000);

  padding-bottom: var(--uismLinkLineWeight);
  position: relative;
}

.sm-link_padding-bottom::after{
  content: "";
  width: 100%;
  height: var(--uismLinkLineWeight);
  background-color: var(--uismLinkLineColor);

  position: absolute;
  left: 0;
  bottom: 0;
}

/* sm-link_bg */

.sm-link_bg {
  --uismLinkLineColor: var(--smLinkLineColor, #000);
  --uismLinkTextColorHover: var(--smLinkTextColorHover, #fff);
  --uismLinkPadding: var(--smLinkPadding, 5px);

  padding: var(--uismLinkPadding);
  transition: color .3s ease-out;
}

.sm-link_bg::before,
.sm-link_bg::after{
  content: "";
  background-color: var(--uismLinkLineColor);
  opacity: 0;
  position: absolute;

  transition: transform .2s ease-out, opacity .2s ease-out .03s;
}

.sm-link_bg .sm-link__label{
  position: relative;
  z-index: 2;
}

.sm-link_bg:hover::before,
.sm-link_bg:hover::after{
  opacity: 1;
  transition-duration: .35s, .35s;
  transition-delay: 0s, 0s;
}

.sm-link_bg:hover{
  color: var(--uismLinkTextColorHover);
}

/* sm-link_text */

.sm-link_text::before{
  content: attr(data-sm-link-text);
  color: var(--uismLinkTextColorHover);
  position: absolute;
}

.sm-link_text::before,
.sm-link_text .sm-link__label{
  transition-property: transform;
  transition-timing-function: cubic-bezier(.86, .6, .08, 1.01);
  transition-duration: .3s;
}

.sm-link_text:hover::before,
.sm-link_text:hover .sm-link__label{
  transition-duration: .4s;
}


.sm-link-standard::after{
  transform: translate3d(-100%, 0, 0);
  transition: transform .2s ease-in;
}

.sm-link-standard:hover::after{
  transform: translate3d(0, 0, 0);
}
